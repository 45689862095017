import React from 'react';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';

export default ({ location }) => (
  <Layout location={location}>
    <Helmet title="FAQ" />
    <section className="px-10 py-10" style={{ minHeight: 'calc(100vh - 80px - 415px' }}>
      <h1>FAQ</h1>
      <p>
        Officia reprehenderit labore cupidatat deserunt incididunt consequat occaecat mollit occaecat anim enim.
        Exercitation laboris ipsum tempor amet do anim sint cillum non est aute. Velit officia velit anim officia
        consequat laborum ad nulla ullamco minim dolor dolor. Mollit tempor ea fugiat minim id irure enim dolore sunt
        sit ullamco.
      </p>
      <p>
        Amet irure irure duis duis eiusmod non eu excepteur. Sunt qui aliqua magna fugiat incididunt esse nulla aliqua.
        Aute aute tempor quis pariatur officia. Qui sit duis occaecat id ut ea culpa. Consequat ad dolor et adipisicing
        consequat ut labore reprehenderit magna dolore ipsum. Occaecat do eiusmod labore laborum aute non ex occaecat
        duis tempor voluptate.
      </p>
      <p>
        Cras et finibus odio, et semper purus. Donec facilisis hendrerit nulla, a consequat nisl dictum sed. In sit amet
        vestibulum neque, id ultricies leo. Sed semper eget felis nec varius. In massa nunc, convallis quis enim ut,
        varius ornare neque. Nullam aliquet risus a iaculis luctus. Vivamus id urna eleifend eros luctus pellentesque.
        Aliquam gravida molestie nunc non ultricies. Etiam eleifend consectetur quam et viverra. Nunc sagittis, tortor
        id interdum rutrum, sapien ante bibendum ex, non cursus leo lorem a ipsum. Quisque sem lorem, feugiat sed quam
        id, lobortis aliquam nisl. Aenean aliquam velit et ultrices maximus. Vivamus vehicula imperdiet augue, quis
        viverra felis lacinia sit amet.
      </p>
    </section>
  </Layout>
);
